import {AfterViewChecked, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthenticatedUser} from '../../../../../classes/authenticated-user-class';
import {AmplifyService} from 'aws-amplify-angular';
import {Subscription} from 'rxjs';
import {AiService} from '../services/ai.service';
import {TranslateService} from '@ngx-translate/core';
import {WebsocketService} from '../../../websocket/websocket.service';

@Component({
    selector: 'app-ai-chat',
    templateUrl: './ai-chat.component.html',
    styleUrls: ['./ai-chat.component.scss']
})
export class AiChatComponent implements OnInit, OnDestroy, AfterViewChecked {

    @Input() campaignId;

    copying = [];

    chatAiGroupForm: FormGroup;
    user: AuthenticatedUser;

    profile_image;
    email;
    username;
    chatResponse;

    generatingText = false;
    errorGeneratingText = false;
    loadingChat = false;
    moreSuggestions = false;
    firstTimeSendText;

    accessToken = '';

    chatConversation = [
        {
            message: this.translate.instant('ai.chat.startChartText'),
            role: 'assistant',
        }
    ];

    suggestions = [];

    @ViewChild('chatContainer', { static: true }) chatContainer: ElementRef;

    private subscriptions: Subscription[] = [];

    constructor(
        private _amplifyService: AmplifyService,
        private _aiService: AiService,
        private _webSocket: WebsocketService,
        private translate: TranslateService,
        private renderer: Renderer2,
        private zone: NgZone
    ) {
        this.chatAiGroupForm = new FormGroup({
            'chat': new FormControl('')
        });
    }

    ngOnInit() {
        this.loadingChat = true;
        this.getSuggestions();
        this.getConversations();
        this.getCognitoData();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngAfterViewChecked() {
        this.scrollChatToBottom();
    }

    // Esta función se encarga de desplazar automáticamente el scroll hacia abajo
    scrollChatToBottom() {
        this.zone.run(() => {
            try {
                this.renderer.setProperty(this.chatContainer.nativeElement, 'scrollTop', this.chatContainer.nativeElement.scrollHeight);
            } catch (err) {
                console.error(err);
            }
        });
    }


    async getCognitoData() {
        const user = await this._amplifyService.auth().currentAuthenticatedUser();
        this.user = new AuthenticatedUser(user);
        this.accessToken = user.signInUserSession.accessToken.jwtToken;
        this.profile_image = this.getUrlImageWithTime(this.user.getAttribute('picture'));
        if (this.profile_image === undefined) {
            this.profile_image = 'assets/img/png/default-user.png';
        }
        this.email = this.user.getAttribute('email');
        this.username = this.user.getAttribute('custom:company_name');
    }

    getSuggestions() {
        this.subscriptions.push(
            this._aiService.getSuggestions().subscribe( response => {
                this.suggestions = response.body;
            })
        );
    }


    getConversations() {
        this.generatingText = true;
        this.subscriptions.push(
            this._aiService.getConversations(this.campaignId).subscribe( response => {
                if (response.body.length > 0) {
                    this.chatConversation = this.chatConversation.concat(response.body);
                } else {
                    this.chatAiGroupForm.controls.chat.setValue(this.translate.instant('ai.chat.textAreaFirstMessage'));
                }
                if (this.chatConversation[this.chatConversation.length - 1]['conversation_running']) {
                    this.chatConversation = [];
                    setTimeout( () => { this.getConversations(); }, 5000 );
                    // this.regenerateResponse(this.chatConversation[this.chatConversation.length - 1]['conversation_count']);
                } else {
                    this.loadingChat = false;
                    this.generatingText = false;
                }
            }, () => {
                this.loadingChat = false;
                this.generatingText = false;
            })
        );
    }

    copyMessageToClipBoard(message, index) {
        this.copying[index] = true;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = message;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        // this.openSnackBar('Copied to clipboard', 'Close');
        setTimeout( () => { this.copying[index] = false; }, 700 );
    }

    getUrlImageWithTime(url_image) {
        if (url_image !== 'n/a') {
            return url_image + '?ts=' + new Date().getTime();
        }
    }

    prompt(name) {
        const translationMap = {
            'web-traffic': 'ai.chat.webTraffic',
            'awareness': 'ai.chat.awareness',
        };
        const translationKey = translationMap[name] || name;
        this.chatAiGroupForm.controls['chat'].setValue(this.translate.instant(translationKey));
    }

    stopGenerating() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this._webSocket.closeConexion();
        this.chatAiGroupForm.controls['chat'].enable();
        this.generatingText = false;
        this.errorGeneratingText = true;
    }

    addMessageToChat() {
        this.chatConversation.push(
            {
                message: this.chatAiGroupForm.controls['chat'].value,
                role: 'user',
            }
        );
        this.firstTimeSendText = true;
        this.generatingText = true;
        this.chatAiGroupForm.controls['chat'].disable();
        this.scrollChatToBottom();
        this.generateIdea();
    }

    generateIdea() {
        this.getWebSocketConversation(this.chatAiGroupForm.controls['chat'].value);
    }

    regenerateResponse(count) {
        this.generatingText = true;
        this.getWebSocketConversation('', count);
    }

    getWebSocketConversation(chatValue, count?) {
        const countParam = count ? count : undefined;
        this._webSocket.briefGenerateConversation(this.campaignId, chatValue, this.accessToken, countParam);
        this.getConversation();
    }

    getConversation() {
        this.subscriptions.push(this._webSocket.getFinishedConversation().subscribe((conversation) => {
            if (this.firstTimeSendText) {
                this.chatConversation.push(
                    {
                        message: conversation,
                        role: 'assistant',
                    }
                    );
                this.firstTimeSendText = false;
                this.scrollChatToBottom();
            } else {
                this.chatConversation[this.chatConversation.length - 1] = {
                    message: conversation,
                    role: 'assistant',
                };
                this.scrollChatToBottom();
            }
            // Supongamos que this.chatConversation[this.chatConversation.length - 1].message es un array de strings
            const arrayDeStrings = this.chatConversation[this.chatConversation.length - 1].message;
            this.chatConversation[this.chatConversation.length - 1].message = arrayDeStrings.join('');
            }, () => {
            this.errorGeneratingText = true;
            this.generatingText = false;
            this.firstTimeSendText = false;
            this.chatAiGroupForm.controls['chat'].enable();
            }, () => {
            this.firstTimeSendText = false;
            this.generatingText = false;
            this.chatAiGroupForm.controls['chat'].setValue('');
            this.chatAiGroupForm.controls['chat'].enable();
        })
        );
    }

}
