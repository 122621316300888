import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-view-more-modal',
  templateUrl: './view-more-modal.component.html',
  styleUrls: ['./view-more-modal.component.scss']
})


export class ViewMoreModalComponent implements OnInit {

  displayedColumns: string[] = [];
  dataSource = this.data.data;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.col3) {
      this.displayedColumns = [this.data.col1, this.data.col2,  this.data.col3];
    } else {
      this.displayedColumns = [this.data.col1, this.data.col2];
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }
    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'hashtags':
          return this.compare(a.hashtag, b.hashtag, isAsc);
        case 'mentions':
          return this.compare(a.hashtag, b.hashtag, isAsc);
        case 'percent':
          return this.compare(a.percent, b.percent, isAsc);
        case 'names':
          return this.compare(a.username, b.username, isAsc);
        case 'languages':
          return this.compare(a.name, b.name, isAsc);
        case 'countries':
          return this.compare(a.name, b.name, isAsc);
        case 'followers':
          return this.compare(a.followers, b.followers, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  redirectLookalike(username, id) {
    // if (!this.inSharePublic) {
    //   const url = this.router.serializeUrl(this.router.createUrlTree(['/app/influencer'], {
    //     queryParams: {
    //       id: params.id,
    //       username: params.username,
    //       network: params.network,
    //     }
    //   }));
    //   const newTab = window.open(url, '_blank');
    //   if (newTab) {
    //     newTab.opener = null;
    //   }
    // } else {
    let url = '';
    if (this.data.network === 'instagram') {
      url = 'https://www.instagram.com/' + username;
    } else if (this.data.network === 'youtube') {
      url = 'https://www.youtube.com/channel/' + id;

    } else if (this.data.network === 'tiktok') {
      url = 'https://www.tiktok.com/@' + username;
    }
    window.open(url, '_blank');
    // }
  }

}
