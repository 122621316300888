import {Component, Input, OnInit} from '@angular/core';
import {Influencer} from '../../../../../../../types/influencer/influencer';

@Component({
  selector: 'app-influencer-profile-stats',
  templateUrl: './influencer-profile-stats.component.html',
  styleUrls: ['./influencer-profile-stats.component.scss']
})
export class InfluencerProfileStatsComponent implements OnInit {

  @Input() influencer: Influencer;
  @Input() currency_type;
  @Input() currency_symbol;

  constructor() { }

  ngOnInit() {}

}
