import {Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../../modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-interests-affinity',
  templateUrl: './interests-affinity.component.html',
  styleUrls: ['./interests-affinity.component.scss']
})
export class InterestsAffinityComponent implements OnInit {

  @Input() data;
  col1: string;

  constructor(
      private dialog: MatDialog,
      private translate: TranslateService
  ) { }

  ngOnInit() {
    this.data.forEach(data => {
      if (Object.keys(data)[1] === 'icon') {
        this.col1 = 'icon';
      } else {
        this.col1 = 'thumbnail';
      }
    });
  }

  errorHandler(event) {
    event.target.src = '/assets/img/png/user_not_exists.png';
  }

  openViewMoreModal(data) {
    if (data[0].weight) {
      this.dialog.open(ViewMoreModalComponent, {
        width: 'auto',
        autoFocus: false,
        maxHeight: '90vh',
        data: {
          data: data,
          col1: this.col1,
          col2: 'names',
          col3: 'weight',
          col4: 'affinity',
          // titleModal: 'Affinity'
          titleModal:  this.translate.instant('influencerProfile.viewMoreModal.affinity')
        },
      });
    } else {
      this.dialog.open(ViewMoreModalComponent, {
        width: 'auto',
        autoFocus: false,
        maxHeight: '90vh',
        data: {
          data: data,
          col1: this.col1,
          col2: 'names',
          // titleModal: 'Interests',
          titleModal:  this.translate.instant('influencerProfile.viewMoreModal.interests')

        },
      });
    }
  }
}
