import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {User} from '../../types/user';
import {Login} from '../../types/login';
import {map} from 'rxjs/operators';
import {RegisterRequest} from '../../types/register-request';
import {RegisterForm} from '../../types/register-form';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private apiUrl = environment.aws_resources.lambda_endpoint.api;
  private accessSetUrl = environment.aws_resources.lambda_endpoint.api + 'plans/user-access';
  private client_id_cognito = environment.aws_resources.cognito.client_id;

  constructor(private amplify: AmplifyService, private http: HttpClient,
              private _authenticationService: AuthenticationService) {}

  getAccessSets() {
    return this.http
      .get(this.accessSetUrl)
      .pipe(map(rawAccessSet => rawAccessSet['user_access']));
  }

  getCurrentSession() {
    const promise = this.amplify
      .auth()
      .currentSession()
      .then(_ => this.amplify.auth().currentAuthenticatedUser())
      .catch(err => err);
    return from(promise).pipe(
      map(response => this.parseCognitoSignInUser(response))
    );
  }

  login(user: Login) {
    this.removeCognitoDeviceKey();
    return from(this.amplify.auth().signIn(user)).pipe(
      map(response => this.parseCognitoSignInUser(response))
    );
  }

  autoLogin(token) {
    let params = new HttpParams();
    token = token + '=';
    params = params.append('authentication_token', token);
    return this.http.get(this.apiUrl + 'authentication/auto_login', {observe: 'response', params});

    // return this._authenticationService.autoLoginRequest(token).pipe(
    //   map(response => this.parseCognitoSignInUser(response))
    // );
  }

  forgotPassword(email: string) {
    return from(this.amplify.auth().forgotPassword(email));
  }

  submitForgotPassword({ code, email, password }) {
    const stringCode = code.toString();

    return from(
      this.amplify.auth().forgotPasswordSubmit(email, stringCode, password)
    );
  }

  signUp(form: RegisterForm) {
    return from(
      this.amplify.auth().signUp(this.processRegisterForm(form))
    ).pipe(map(_ => form.email));
  }

  signUpStep1(email) {
    const body = {
      email: email.email,
      source: 'step_1',
      platform_id: environment.platform_id,
    };
    return this.http.put(this.apiUrl + 'account-management/pre_register', body);
  }

  getEmailFromHash(hash) {
    return this.http.get(this.apiUrl + 'account-management/register/' + hash);
  }

  resendVerificationEmail(email: string) {
    return from(this.amplify.auth().resendSignUp(email));
  }


  generateHMACIntercom (msg: string) {
    if (msg) {
      const hash = CryptoJS.HmacSHA256(msg, 'we2Y3l88_B4DgDuyaTISFlAv0AbRzFTPMKBn1P-1').toString(CryptoJS.enc.Hex);
      if (hash) {
        return hash;
      } else {
        return '123fail';
      }
    }
  }

  private removeCognitoDeviceKey() {
    const regexDeviceKey = new RegExp(`^CognitoIdentityServiceProvider\.${this.client_id_cognito}\..*\.deviceKey$`);
    Object.keys(localStorage).forEach(key => {
      if (regexDeviceKey.test(key)) {
        const device_key = localStorage.getItem(key);
        localStorage.removeItem(key);
      }
    });
  }

  private parseCognitoSignInUser(raw: any): User {
    const dataParsed =  {
      accessToken: raw.signInUserSession.accessToken.jwtToken,
      refreshToken: raw.signInUserSession.refreshToken.token,
      username: raw.username,
      userHash: this.generateHMACIntercom(raw.username),
      attributes: {
        newsletter: raw.attributes['custom:newsletter'],
        planType: raw.attributes['custom:plan_type'],
        privacyPolicy: raw.attributes['custom:privacy_policy'],
        validatedAccount: raw.attributes['custom:validated_account'],
        workedInfluencers: raw.attributes['custom:worked_influencers'],
        currencyType: raw.attributes['custom:currency_type'],
        emailVerified: raw.attributes['email_verified'],
        phoneNumberVerified: raw.attributes['phone_number_verified'],
        clientAgreement: raw.attributes['custom:client_agreement'],
        city: raw.attributes['custom:city'],
        zipCode: raw.attributes['custom:zip_code'],
        margin: parseFloat(raw.attributes['custom:custom_margin']),
        taxes: parseFloat(raw.attributes['custom:taxes']),
      },
    };
    if (raw.username) {
      dataParsed['userHash'] = this.generateHMACIntercom(raw.username);
    }
    return dataParsed;
  }

  private processRegisterForm(registerForm: RegisterForm): RegisterRequest {
    const attributes = Object.assign(
      {},
      new RegisterRequest()
    ) as RegisterRequest;
    attributes['username'] = registerForm['email'];
    attributes['email'] = registerForm['email'];
    attributes['password'] = registerForm['password'];
    attributes['attributes']['custom:currency_type'] = registerForm['custom:currencyType'];
    for (const key in attributes['attributes']) {
      if (key === 'phone_number') {
        attributes['attributes'][key] = this.checkIfPhoneIsEmpty(
          registerForm['phone_code'],
          registerForm[key]
        );
      } else {
        try {
          attributes['attributes'][key] = registerForm[key].toString();
        } catch (error) {
          attributes['attributes'][key] = attributes['attributes'][key];
        }
      }
    }
    return attributes;
  }

  /**
   * Check if phone number is empty if so return a empty string else return phone number with phone code
   * @param {string} phoneCode Phone code value
   * @param {string} phoneNumber Phone number value without code
   * @return {string} phone_number_value Phone number value
   */
  private checkIfPhoneIsEmpty(phoneCode: string, phoneNumber: string): string {
    const prefix = '+';
    let phone = '';
    if (phoneNumber.length !== 0) {
      phone = prefix + phoneCode + phoneNumber;
    } else {
      phone = '+340000000';
    }
    return phone;
  }

  autoLoginRequest(loginToken) {
    let params = new HttpParams();
    loginToken = loginToken + '=';
    params = params.append('authentication_token', loginToken);
    return this.http.get(this.apiUrl + 'authentication/auto_login', {observe: 'response', params});
    // const tokenData = this.http.get(this.apiUrl + 'authentication/auto_login', {observe: 'response', params});
    // const userSession = new CognitoUserSession(tokenData['AuthenticationResult'].AccessToken);
    // console.log(userSession);
  }

  getPlanExpirationData() {
    return this.http.get(this.apiUrl + 'plans/user-subscription-data');
  }

  getUserInvoices() {
    return this.http.get(this.apiUrl + 'plans/billing-info',
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getCrawlsLimit() {
    return this.http.get(this.apiUrl + 'plans/requests-summary',
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateLanguageAccessSets(language) {
    const body = {
      // 'language':  language,
    };
    let params = new HttpParams();
    params = params.append('language', language);
    return this.http.put(this.apiUrl + 'account-management/change_language', body,
        {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }

  getPublicCurrencies() {
    return this.http.get(this.apiUrl + 'account-management/get_list_currencies',
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  getPrivateCurrencies() {
    return this.http.get(this.apiUrl + 'account-management/private/get_list_currencies',
        {observe: 'response'}).pipe(map((data: any) => {
      return data;
    }));
  }

  updateCurrency(currency) {
    const body = {};
    let params = new HttpParams();
    params = params.append('currency', currency);
    return this.http.put(this.apiUrl + 'account-management/change_currency', body,
        {observe: 'response', params}).pipe(map((data: any) => {
      return data;
    }));
  }
}
