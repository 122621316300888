import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-spinner-chart',
  templateUrl: './progress-spinner-chart.component.html',
  styleUrls: ['./progress-spinner-chart.component.scss']
})
export class ProgressSpinnerChartComponent implements OnInit {

  @Input() chartData;

  constructor() { }

  ngOnInit() {
  }

}
