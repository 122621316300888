import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AdminManagementService} from '../../../../store/admin-management.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {CompleteUserAttributesComponent} from '../../modals/complete-user-attributes/complete-user-attributes.component';
import {AuthenticationService} from '../../../../../../store/auth/authentication.service';

@Component({
  selector: 'app-user-attributes',
  templateUrl: './user-attributes.component.html',
  styleUrls: ['./user-attributes.component.scss']
})
export class UserAttributesComponent implements OnInit, OnDestroy {

  @Input() cognitoSettings;
  attributesForm: FormGroup;
  attributesResponse = {};
  attributesForUpdate = [];
  currencyTypesValues = [];
  companyLogoUrl = '';
  companyLogoName = '';
  changesAttributes = 0;
  private subscriptions = [];

  companyLogoNameAndValues = [
    {
      'Name': 'Grosso',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/grosso.png',
    },
    {
      'Name': 'Binfluencer',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/binfluencer.png',
    },
    {
      'Name': 'Adgravity',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/adgravity.png'
    },
    {
      'Name': 'Ipi',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/ipi.png'
    },
    {
      'Name': 'AlkimiAds',
      'Value': 'https://test-pax-company-logo-image.s3-eu-west-1.amazonaws.com/alkimiads.png',
    }
  ];

  constructor( private adminManagementService: AdminManagementService,
               private snackBar: MatSnackBar,
               private _authService: AuthenticationService,
               public dialog: MatDialog) {
    this.attributesForm = new FormGroup({
      'custom:client_agreement': new FormControl(''),
      'custom:validated_account': new FormControl(''),
      'custom:company_logo_image': new FormControl(''),
      'custom:currency_type': new FormControl(''),
      'custom:custom_margin': new FormControl(''),
    });
  }

  ngOnInit() {
    this.subscriptions.push(
        this._authService.getPublicCurrencies().subscribe( response => {
          this.currencyTypesValues = response.body;
        }, () => {
        })
    );
    this.setAccountForm(this.cognitoSettings);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  setAccountForm(attributes) {
    const name_attributes = [
      'custom:validated_account',
      'custom:currency_type', 'custom:custom_margin', 'email'
    ];
    // Get the parameters necessary for show
    for (let index_attribute = 0; index_attribute < attributes.length; index_attribute++) {
      for (let index_name_attributes = 0; index_name_attributes < name_attributes.length; index_name_attributes++) {
        if (attributes[index_attribute]['Name'] === name_attributes[index_name_attributes]) {
          this.attributesResponse[attributes[index_attribute]['Name']] = attributes[index_attribute]['Value'];
        }
      }
    }
    // Set the values in the form
    this.attributesForm.controls['custom:validated_account'].setValue(this.attributesResponse['custom:validated_account']);
    this.attributesForm.controls['custom:currency_type'].setValue(this.attributesResponse['custom:currency_type']);
    this.attributesForm.controls['custom:custom_margin'].setValue(this.attributesResponse['custom:custom_margin']);
  }


  saveChanges() {
    this.viewCompany(this.attributesForm.controls['custom:company_logo_image'].value);

    // Observable for call the service, function updateAttributes
    this.subscriptions.push(
      this.adminManagementService.updateAttributes(this.attributesResponse['email'], this.attributesForUpdate).subscribe(result => {
        if (result['status'] === 200) {
          window.scroll(0, 0);
          document.getElementById('currencyType').classList.remove('border-orange');
          document.getElementById('customMargin').classList.remove('border-orange');
          document.getElementById('validatedAccount').classList.remove('border-orange');
          this.attributesResponse['custom:validated_account'] = this.attributesForm.controls['custom:validated_account'].value;
          this.attributesResponse['custom:custom_margin'] = this.attributesForm.controls['custom:custom_margin'].value;
          this.attributesResponse['custom:currency_type'] = this.attributesForm.controls['custom:currency_type'].value;
          this.openSnackBar('Updated successfully', 'Close');
        }
      }, error => {
        window.scroll(0, 0);
        this.openSnackBar('Something went wrong', 'Close');
      })
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['custom-snackbar']
    });
  }


  moreInfoUserAttributes() {
    const dialogRef = this.dialog.open(CompleteUserAttributesComponent, {
      width: 'auto',
      height: 'auto',
      autoFocus: false,
      data: {
        user_config: this.cognitoSettings,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  viewCompany(companyLogoImage) {
    this.companyLogoNameAndValues.forEach( (key) => {
      if (key['Name'] === companyLogoImage) {
        this.companyLogoUrl = key['Value'];
      } else if (key['Value'] === companyLogoImage) {
        this.companyLogoName = key['Name'];
      }
    });
  }

  onChange(event, attributeName) {
    if (attributeName === 'validatedAccount') {
      if (event.value !== this.attributesResponse['custom:validated_account']) {
        this.changesAttributes += 1;
        document.getElementById('validatedAccount').classList.add('border-orange');
        this.attributesForUpdate.push(
            {
              'Name': 'custom:validated_account',
              'Value': event.value
            }
        );
      } else {
        this.changesAttributes -= 1;
        document.getElementById('validatedAccount').classList.remove('border-orange');
      }
    }
    if (attributeName === 'customMargin') {
      if (String(event) === '0' && String(this.attributesResponse['custom:custom_margin']) === '0.0') {
        document.getElementById('customMargin').classList.remove('border-orange');
      } else if (event !== this.attributesResponse['custom:custom_margin']) {
        this.changesAttributes += 1;
        document.getElementById('customMargin').classList.add('border-orange');
        this.attributesForUpdate.push(
            {
              'Name': 'custom:custom_margin',
              'Value': String(event)
            }
        );
      } else {
        this.changesAttributes -= 1;
        document.getElementById('customMargin').classList.remove('border-orange');
      }
    }
    if (attributeName === 'currencyType') {
      if (String(event.value) !== String(this.attributesResponse['custom:currency_type'])) {
        this.changesAttributes += 1;
        document.getElementById('currencyType').classList.add('border-orange');
        this.attributesForUpdate.push(
            {
              'Name': 'custom:currency_type',
              'Value': event.value
            }
        );
      } else {
        this.changesAttributes -= 1;
        document.getElementById('currencyType').classList.remove('border-orange');
      }
    }

  }

}
