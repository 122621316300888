import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {ModalPlansService} from '../../../../store/modal-plans.service';
import {Subscription} from 'rxjs';
import {getAccessSets, getLoggedUser} from '../../../../../../store/auth/authentication.actions';
import {MAT_DIALOG_DATA, MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {ModalThanksPageComponent} from '../../../modal-thanks-page/modal-thanks-page.component';


@Component({
  selector: 'app-modal-confirm-upgrade-plan',
  templateUrl: './modal-confirm-upgrade-plan.component.html',
  styleUrls: ['./modal-confirm-upgrade-plan.component.scss']
})
export class ModalConfirmUpgradePlanComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  loading;

  constructor(private store: Store<State>,
              private modalPlansService: ModalPlansService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              public router: Router,
              private dialogRef: MatDialog,
  ) {

  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  confirmUpgradePlan() {
      this.loading = true;
      const plan_to_update = {
          plan_id: this.data.productId,
          plan_name: this.data.plan.planName,
      };
        this.subscriptions.push(
            ...[
                this.modalPlansService.upgradePlan(plan_to_update).subscribe( result => {
                  this.dialogRef.closeAll();
                  this.store.dispatch(getLoggedUser());
                  this.store.dispatch(getAccessSets());
                    this.dialogRef.open(ModalThanksPageComponent, {
                        width: '950px',
                        height: '700px',
                        autoFocus: false,
                        data: {
                            plan: this.data.plan,
                        }
                    });
                  this.loading = false;
                }, () => {
                    this.loading = false;
                    this.openSnackBar('Oops, something went wrong, please contact with us', 'Close');
                })
            ]
        );
  }

  openSnackBar(message, action) {
      this.snackBar.open(message, action, {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
      });
  }


}
