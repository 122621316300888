import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CampaignsService} from '../../../../services/campaigns.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../../../store/auth/authentication.service';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.scss']
})
export class NewCampaignComponent implements OnInit {

  new_campaign_form: FormGroup;

  campaignIdSelectedForMultiple = '';

  addingMultipleInfluencers = false;
  loading = false;


  currencies = [];
  private subscriptions: Subscription[] = [];


  constructor(private _campaignsService: CampaignsService,
              public dialogRef: MatDialogRef<NewCampaignComponent>,
              public router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<State>,
              private _authService: AuthenticationService,
              public snackBar: MatSnackBar) {
    this.new_campaign_form = new FormGroup({
      'campaign_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ]),
      'custom:currency_type': new FormControl('USD', [
        Validators.required,
      ]),
    });
  }

  ngOnInit() {
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.new_campaign_form.controls['custom:currency_type'].setValue(user.currency);
    });
    this.subscriptions.push(
        this._authService.getPublicCurrencies().subscribe( response => {
          this.currencies = response.body;
        }, () => {
        })
    );
  }


  createCampaign(campaign_name, currencySelected) {
    this.addingMultipleInfluencers  = false;
    this.loading = true;
    if (this.new_campaign_form.valid) {
      this._campaignsService.createCampaign(campaign_name, currencySelected).subscribe( result => {
        if (result['status'] === 201) {
          if (this.data.influencers) {
            this.addInfluencerToCampaign(result.body.campaign_id, this.data.influencers);
          } else {
            this.openCustomSuccessSnackbar('Campaign created successfully', result.body.campaign_id);
            const result_dialog = {
              status: 'added',
              refresh: result['body']['refresh_token']
            };
            this.dialogRef.close(result_dialog);
          }
        } else {
          this.dialogRef.close();
          this.openCustomErrorSnackbar('There has been an error. Please try again.');
        }
      }, error => {
        this.dialogRef.close();
        if (error.error.message) {
          this.openCustomErrorSnackbar(error.error.message);
        } else {
          this.openCustomErrorSnackbar('There has been an error. Please try again.');
        }
      });
    }
  }

  openCustomSuccessSnackbar(message, campaign_id) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message, type: 'success', campaign_id: campaign_id },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar',
    });
  }

  openCustomErrorSnackbar(message) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message, type: 'error' },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar',
    });
  }

  addInfluencerToCampaign(campaign_id, influencers) {
    this._campaignsService.addInfluencerToCampaign(campaign_id, influencers).subscribe(result => {
      this.openCustomSuccessSnackbar(result.body.message, campaign_id);
      const result_dialog = {
        status: 'added',
        refresh: result['body']['refresh_token']
      };
      this.dialogRef.close(result_dialog);
    }, error => {
      if (error.error.message) {
        this.openCustomErrorSnackbar(error.error.message);
      } else {
        this.openCustomErrorSnackbar('There has been an error. Please try again.');
      }
    });
  }

  viewCampaign(campaign_id) {
    this.router.navigate(['/app/campaign', campaign_id]);
    this.dialogRef.close();
  }


}
