import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {selectAppViewPort} from '../../../../../../store/app/app.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../../../store';
import {FormControl, FormGroup} from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CampaignsModalComponent } from '../../../campaigns-modal/campaigns-modal.component';
import { ModalRejectReasonComponent } from 'src/app/components/share/shared-campaign/modals/modal-reject-reason/modal-reject-reason.component';
import { RemoveInfluencerComponent } from '../../../remove-influencer/remove-influencer.component';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-campaign-plan-influencers',
  templateUrl: './campaign-plan-influencers.component.html',
  styleUrls: ['./campaign-plan-influencers.component.scss']
})
export class CampaignPlanInfluencersComponent implements OnInit, OnDestroy {

  @Input() ready_influencers;
  @Input() backup_influencers;
  @Input() pending_influencers;
  @Input() not_available_influencers;

  @Input() campaignId;
  @Input() currency_code;
  @Input() currency_symbol;
  @Input() planNameShown;
  @Input() is_share;
  @Input() is_internal_report;
  @Input() CAN_UNIQUE_AUDIENCE;


  @Output() callOpenProfile = new EventEmitter<any>();
  @Output() callRefreshChanges = new EventEmitter<any>();
  @Output() callGetCampaign = new EventEmitter<any>();

  influencersReadyInstagram: [];
  influencersReadyYoutube: [];
  influencersReadyTiktok: [];

  influencersBackupInstagram: [];
  influencersBackupYoutube: [];
  influencersBackupTiktok: [];

  influencersPendingInstagram: [];
  influencersPendingYoutube: [];
  influencersPendingTiktok: [];

  influencersNotAvailableInstagram: [];
  influencersNotAvailableYoutube: [];
  influencersNotAvailableTiktok: [];

  filteredInfluencersReadyInstagram;
  filteredInfluencersReadyYoutube;
  filteredInfluencersReadyTiktok;

  filteredInfluencersBackupInstagram;
  filteredInfluencersBackupYoutube;
  filteredInfluencersBackupTiktok;

  filteredInfluencersPendingInstagram;
  filteredInfluencersPendingYoutube;
  filteredInfluencersPendingTiktok;

  filteredInfluencersNotAvailableInstagram;
  filteredInfluencersNotAvailableYoutube;
  filteredInfluencersNotAvailableTiktok;

  viewport: string;
  typeViewInfluencer = 'list';

  networkClicked = false;
  isInstagramNetworkSelected = false;
  isYoutubeNetworkSelected = false;
  isTiktokNetworkSelected = false;
  searchedFilter = false;

  influencerUsernameForm: FormGroup;

  influencersCheckboxSelected = [];

  // variables para el mat-menu de seleccion multiple de influencers
  allInfluencersAreAdded = true;
  allInfluencersAreBackup = true;
  showMatMenuActionsState = true;

  private subscriptions: Subscription[] = [];

  constructor(
      public router: Router,
      private store: Store<State>,
      private el: ElementRef,
      private dialog: MatDialog,
      private _campaignsService: CampaignsService,
      private snackBar: MatSnackBar,
      private translate: TranslateService,

  ) {
    this.influencerUsernameForm = new FormGroup({
      'influencerUsername': new FormControl('')
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });

    // influencers ready
    this.influencersReadyInstagram = this.ready_influencers.filter(influencer => influencer.network === 'instagram');
    this.influencersReadyYoutube = this.ready_influencers.filter(influencer => influencer.network === 'youtube');
    this.influencersReadyTiktok = this.ready_influencers.filter(influencer => influencer.network === 'tiktok');
    this.filteredInfluencersReadyInstagram  = this.influencersReadyInstagram;
    this.filteredInfluencersReadyYoutube =  this.influencersReadyYoutube;
    this.filteredInfluencersReadyTiktok = this.influencersReadyTiktok;

    /// influencers additional
    this.influencersBackupInstagram = this.backup_influencers.filter(influencer => influencer.network === 'instagram');
    this.influencersBackupYoutube = this.backup_influencers.filter(influencer => influencer.network === 'youtube');
    this.influencersBackupTiktok = this.backup_influencers.filter(influencer => influencer.network === 'tiktok');
    this.filteredInfluencersBackupInstagram  = this.influencersBackupInstagram;
    this.filteredInfluencersBackupYoutube =  this.influencersBackupYoutube;
    this.filteredInfluencersBackupTiktok = this.influencersBackupTiktok;

    /// influencers pending
    this.influencersPendingInstagram = this.pending_influencers.filter(influencer => influencer.network === 'instagram');
    this.influencersPendingYoutube = this.pending_influencers.filter(influencer => influencer.network === 'youtube');
    this.influencersPendingTiktok = this.pending_influencers.filter(influencer => influencer.network === 'tiktok');
    this.filteredInfluencersPendingInstagram  = this.influencersPendingInstagram;
    this.filteredInfluencersPendingYoutube =  this.influencersPendingYoutube;
    this.filteredInfluencersPendingTiktok = this.influencersPendingTiktok;


    /// influencers not available
    this.influencersNotAvailableInstagram = this.not_available_influencers.filter(influencer => influencer.network === 'instagram');
    this.influencersNotAvailableYoutube = this.not_available_influencers.filter(influencer => influencer.network === 'youtube');
    this.influencersNotAvailableTiktok = this.not_available_influencers.filter(influencer => influencer.network === 'tiktok');
    this.filteredInfluencersNotAvailableInstagram  = this.influencersNotAvailableInstagram;
    this.filteredInfluencersNotAvailableYoutube =  this.influencersNotAvailableYoutube;
    this.filteredInfluencersNotAvailableTiktok = this.influencersNotAvailableTiktok;
  }


  getAllInfluencersLength(): number {
    let totalLength = 0;

    if (this.ready_influencers) {
      totalLength += this.ready_influencers.length;
    }

    if (this.backup_influencers) {
      totalLength += this.backup_influencers.length;
    }

    if (this.pending_influencers) {
      totalLength += this.pending_influencers.length;
    }

    if (this.not_available_influencers) {
      totalLength += this.not_available_influencers.length;
    }

    return totalLength;
  }


  openInNewTab(route: string): void {
    window.open(this.router.createUrlTree([route]).toString(), '_blank');
  }

  scrollToDiv(divId: string) {
    const element = this.el.nativeElement.querySelector('#' + divId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  changeNetwork(network) {
    this.networkClicked = true;
    if (network === 'instagram') {
      this.isInstagramNetworkSelected = !this.isInstagramNetworkSelected;
    } else if (network === 'youtube') {
      this.isYoutubeNetworkSelected = !this.isYoutubeNetworkSelected;
    } else if (network === 'tiktok') {
      this.isTiktokNetworkSelected = !this.isTiktokNetworkSelected;
    }
    if (!this.isInstagramNetworkSelected && !this.isYoutubeNetworkSelected && !this.isTiktokNetworkSelected) {
      this.networkClicked = false;
    }
  }

  resetNetworks() {
    this.networkClicked = false;
    this.isInstagramNetworkSelected = false;
    this.isYoutubeNetworkSelected = false;
    this.isTiktokNetworkSelected = false;
  }

  filterInfluencersByUsername() {
    const influencerUsername = this.influencerUsernameForm.controls.influencerUsername.value.toLowerCase();
    this.searchedFilter = influencerUsername.length > 0;

    if (!this.networkClicked) {
      this.filteredInfluencersReadyInstagram = this.filterInfluencerByUsername(this.influencersReadyInstagram, influencerUsername);
      this.filteredInfluencersReadyYoutube = this.filterInfluencerByUsername(this.influencersReadyYoutube, influencerUsername);
      this.filteredInfluencersReadyTiktok = this.filterInfluencerByUsername(this.influencersReadyTiktok, influencerUsername);
      this.filteredInfluencersBackupInstagram = this.filterInfluencerByUsername(this.influencersBackupInstagram, influencerUsername);
      this.filteredInfluencersBackupYoutube = this.filterInfluencerByUsername(this.influencersBackupYoutube, influencerUsername);
      this.filteredInfluencersBackupTiktok = this.filterInfluencerByUsername(this.influencersBackupTiktok, influencerUsername);
    } else {
      if (this.isInstagramNetworkSelected) {
        this.filteredInfluencersReadyInstagram = this.filterInfluencerByUsername(this.influencersReadyInstagram, influencerUsername);
        this.filteredInfluencersBackupInstagram = this.filterInfluencerByUsername(this.influencersBackupInstagram, influencerUsername);
        this.filteredInfluencersPendingInstagram = this.filterInfluencerByUsername(this.influencersPendingInstagram, influencerUsername);
        this.filteredInfluencersNotAvailableInstagram = this.filterInfluencerByUsername(this.influencersNotAvailableInstagram, influencerUsername);
      }
      if (this.isYoutubeNetworkSelected) {
        this.filteredInfluencersReadyYoutube = this.filterInfluencerByUsername(this.influencersReadyYoutube, influencerUsername);
        this.filteredInfluencersBackupYoutube = this.filterInfluencerByUsername(this.influencersBackupYoutube, influencerUsername);
        this.filteredInfluencersPendingYoutube = this.filterInfluencerByUsername(this.influencersPendingYoutube, influencerUsername);
        this.filteredInfluencersNotAvailableYoutube = this.filterInfluencerByUsername(this.influencersNotAvailableYoutube, influencerUsername);
      }
      if (this.isTiktokNetworkSelected) {
        this.filteredInfluencersReadyTiktok = this.filterInfluencerByUsername(this.influencersReadyTiktok, influencerUsername);
        this.filteredInfluencersBackupTiktok = this.filterInfluencerByUsername(this.influencersBackupTiktok, influencerUsername);
        this.filteredInfluencersPendingTiktok = this.filterInfluencerByUsername(this.influencersPendingTiktok, influencerUsername);
        this.filteredInfluencersNotAvailableTiktok = this.filterInfluencerByUsername(this.influencersNotAvailableTiktok, influencerUsername);
      }
    }
  }

  filterInfluencerByUsername(influencers: any[], username: string): any[] {
    return influencers.filter(influencer => influencer.username.toLowerCase().includes(username));
  }


  callOpenProfileData(event) {
    this.callOpenProfile.emit({influencer_id: event.influencer_id, network: event.network});
  }

  callDetailRefreshChanges() {
    this.callRefreshChanges.emit();
  }

  callDetailGetCampaign() {
    this.callGetCampaign.emit();
  }


  calledAddSelectInfluencers(event) {
    if (event.influencers.length > 1) {
      this.influencersCheckboxSelected = [];
    }
    if (event.checked) {
      event.influencers.forEach(influencer => {
        this.influencersCheckboxSelected.push(influencer);
      });
    } else {
      event.influencers.forEach(influencer => {
        this.influencersCheckboxSelected = this.influencersCheckboxSelected.filter(item => item.influencer_id !== influencer.influencer_id);
      });
    }
    this.allInfluencersAreAdded = this.influencersCheckboxSelected.every(influencer => influencer.state === 'added');
    this.allInfluencersAreBackup = this.influencersCheckboxSelected.every(influencer => influencer.state === 'backup');
    this.showMatMenuActionsState = this.influencersCheckboxSelected.every(influencer => 
      (!influencer.client_approval && influencer.state === 'added') ||
      (influencer.state === 'accepted' && this.is_internal_report) ||
      influencer.state === 'backup'
    );
  }


  switchInfluencer(influencersForUpdate) {
    const influencers = [];
    influencersForUpdate.forEach(influencer => {
      influencers.push({
        influencer_id: influencer.influencer_id,
        current_state: influencer.state
      })
    });
    this.subscriptions.push(
        this._campaignsService
            .updateInfluencerState(this.campaignId, influencers)
            .subscribe(
                () => {
                  this.callGetCampaign.emit();
                },
                () => {
                  this.snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
                    duration: 3000,
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    panelClass: 'error-snackbar',
                  });
                }
            )
    );
  }


  openCampaignsDialog(influencers): void {
    this.dialog.open(CampaignsModalComponent, {
      width: '790px',
      height: '350px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        influencers: influencers
      },
    });
  }


  hideInfluencer(influencersForUpdate, hideValue) {
    const influencer_ids = [];
    influencersForUpdate.forEach(influencer => {
      influencer_ids.push(influencer.influencer_id)
    });
    this.subscriptions.push(
        this._campaignsService.hideInfluencerCampaign(this.campaignId, influencer_ids, hideValue).subscribe( response => {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: response.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
          this.callGetCampaign.emit();
        }, () => {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('customSnackbar.thereHasBeenError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        })
    );
  }

  openApproveCancelInfluencerModal(influencers, action) {
    const dialogRef = this.dialog.open(ModalRejectReasonComponent, {
      width: '490px',
      height: '430px',
      autoFocus: false,
      panelClass: 'remove-padding',
      data: {
        campaign_id: this.campaignId,
        componentCalled: action,
        isShare: this.is_share,
        influencers: influencers
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.callGetCampaign.emit();
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: result.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }


  openRemoveInfluencerDialog(influencers): void {
    const dialogRef = this.dialog.open(RemoveInfluencerComponent, {
      width: '490px',
      height: '320px',
      panelClass: 'remove-padding',
      autoFocus: false,
      data: {
        campaign_id: this.campaignId,
        influencers: influencers
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status != null) {
        if (result.status === 200) {
          this.callGetCampaign.emit();
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: result.body.message, type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: 'Oops something went wrong, please try again.', type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      }
    });
  }

}