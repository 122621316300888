import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';
import {ModalPlansService} from '../../../store/modal-plans.service';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {ModalPlansComponent} from '../modal-plans.component';
import {Router} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {environment} from '../../../../../../environments/environment';
import {Plan} from '../../../types/plans';
import {openConfirmUpgradePlanDialog} from '../../../../../store/app/app.actions';
import {AuthenticatedUser} from '../../../../../classes/authenticated-user-class';
import {AmplifyService} from 'aws-amplify-angular';
import {ModalThanksPageComponent} from '../../modal-thanks-page/modal-thanks-page.component';
import {getAccessSets, getLoggedUser} from '../../../../../store/auth/authentication.actions';

declare var Paddle: any;

@Component({
    selector: 'app-modal-plans-card',
    templateUrl: './modal-plans-card.component.html',
    styleUrls: ['./modal-plans-card.component.scss']
})
export class ModalPlansCardComponent implements OnInit, OnDestroy {

    @Input() plan: Plan;
    @Input() isMonthlyPayment: boolean;
    @Input() email: string;
    @Input() activePlan: boolean;
    @Input() nameActivePlan: string;
    @Input() cancelledPlan: boolean;

    buttonPlanAction;
    canUpgrade = false;

    userSub;
    authenticated_user: AuthenticatedUser;


    private subscriptions: Subscription[] = [];

    constructor(private store: Store<State>,
                private modalPlansService: ModalPlansService,
                private snackBar: MatSnackBar,
                public dialogRef: MatDialogRef<ModalPlansComponent>,
                public router: Router,
                private gtmService: GoogleTagManagerService,
                private _amplifyService: AmplifyService,
                private dialog: MatDialog
    ) {
        Paddle.Setup({
            vendor: environment.paddle_vendor,
        });
        if (!environment.production) {
            Paddle.Environment.set('sandbox');
        }
    }
    async getAllAttributes() {
        const user = await this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
        this.authenticated_user = new AuthenticatedUser(user);
        this.userSub = this.authenticated_user.getAttribute('sub');

    }

    ngOnInit() {
        // this.getAllAttributes();
        if (this.nameActivePlan === 'PERFORMANCE' && (this.plan.planName === 'SEARCH' || this.plan.planName === 'PREMIUM')) {
            this.buttonPlanAction = 'DOWNGRADE';
            this.canUpgrade = false;
        } else if (this.nameActivePlan === 'PREMIUM' && (this.plan.planName === 'SEARCH')) {
            this.buttonPlanAction = 'DOWNGRADE';
            this.canUpgrade = false;
        }  else if (this.nameActivePlan === 'PREMIUM' && (this.plan.planName === 'PERFORMANCE')) {
            this.buttonPlanAction = 'UPGRADE';
            this.canUpgrade = true;
        }  else if (this.nameActivePlan === 'SEARCH' && (this.plan.planName === 'PERFORMANCE' || this.plan.planName === 'PREMIUM')) {
            this.buttonPlanAction = 'UPGRADE';
            this.canUpgrade = true;
        }
    }



    openCheckout(isMonthlyPayment, plan) {
        const productId = isMonthlyPayment ? plan.paddleSubscriptionIdMonthly : plan.paddleSubscriptionIdYearly;
        if ( this.activePlan === true ) {
            this.store.dispatch(openConfirmUpgradePlanDialog({ plan, productId}));
        } else {
            Paddle.Checkout.open(
                {
                    product: productId,
                    email: this.email,
                    disableLogout: true,
                    successCallback: (data) => {
                        this.subscriptions.push(
                            ...[
                                this.modalPlansService.savePaddlePayment(data).subscribe( () => {
                                    this.store.dispatch(getLoggedUser());
                                    this.store.dispatch(getAccessSets());
                                    this.dialogRef.close();
                                    // aqui iria la llamada a googletagmanager
                                    const gtmTag = {
                                        event: 'checkoutSuccess',
                                        data: 'my-custom-event',
                                    };
                                    this.gtmService.pushTag(gtmTag);
                                    this.dialog.open(ModalThanksPageComponent, {
                                        width: '950px',
                                        height: '700px',
                                        autoFocus: false,
                                        data: {
                                            plan: plan,
                                        }
                                    });
                                }, () => {
                                    this.openSnackBar('Oops, something went wrong, please contact with us', 'Close');
                                })
                            ]
                        );
                    },
                    closeCallback: (data) => {
                        // if the user cancel the paddle modal
                        // alert('Your purchase has been cancelled, we hope to see you again soon!');
                        this.openSnackBar('You closed the payment window, please try again', 'Close');
                    }
                }
            );
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    openSnackBar(message, action) {
        this.snackBar.open(message, action, {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
        });
    }

    redirectCallSales() {
        window.open('https://calendly.com/nicolas-11posts/plans-and-pricing', '_blank');
    }

    // Función para formatear el precio, a los precios anuales establecer separador de miles con ,
    formatPrice(price: number): string {
        return price.toLocaleString('en-US');
    }
}
