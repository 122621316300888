import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Influencer} from 'src/app/types/influencer/influencer';
import {ModalShareInfluencerGenerateLinkComponent} from '../../../../private/modals/modal-share-influencer-generate-link/modal-share-influencer-generate-link.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-influencer-avatar',
  templateUrl: './influencer-avatar.component.html',
  styleUrls: ['./influencer-avatar.component.scss']
})
export class InfluencerAvatarComponent implements OnInit {

  @Input() influencer: Influencer;

  @Input() inCard: boolean;

  @Input() inSharePublic: boolean;

  @Output() view = new EventEmitter();

  @Output() add = new EventEmitter();

  @Input() componentNameCalled: string;

  isLoading = true;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  hideLoader() {
    this.isLoading = false;
  }

  // isPrivate(influencer: Influencer) {
  //   return influencer && influencer.type === 'instagram' && influencer.isPrivate;
  // }


  openModalShareInfluencerGenerateLink() {
    const dialogRef = this.dialog.open(ModalShareInfluencerGenerateLinkComponent, {
      width: '400px',
      height: '300px',
      autoFocus: false,
      data: {
        influencerId: this.influencer.id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
