import {Pipe, PipeTransform} from '@angular/core';

// This pipe adds the % suffix to the percentage variables
// NOTE: It has to be used combined with percentPipe (example: {{ data | percent | perCentPipe }})
@Pipe({
  name: 'perCentPipe'
})
export class PerCentSymbolPipe implements PipeTransform {

  transform(money: any): any {
    // Convierte a cadena si money no es una cadena
    money = typeof money === 'string' ? money : money.toString();

    const result = money.split('%');

    if (result.length > 0) {
      let formattedValue = parseFloat(result[0]).toFixed(2); // Redondea a dos decimales

      // Elimina el punto decimal y los ceros si los decimales son .00
      formattedValue = formattedValue.replace(/\.00$/, '');

      return formattedValue + ' %';
    } else {
      console.error('El formato de la cadena money no es válido.');
      return money;
    }
  }


}
