import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Influencer} from '../../../../../types/influencer/influencer';
import {MatDialog} from '@angular/material/dialog';
import {ViewMoreModalComponent} from '../modals/view-more-modal/view-more-modal.component';
// @ts-ignore
import {Label, MultiDataSet} from 'ng2-charts';
import {ChartType} from 'chart.js';
import {environment} from '../../../../../../environments/environment';
import {openPlansDialog} from '../../../../../store/app/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';


@Component({
  selector: 'app-lateral-profile-influencer',
  templateUrl: './lateral-profile-influencer.component.html',
  styleUrls: ['./lateral-profile-influencer.component.scss']
})
export class LateralProfileInfluencerComponent implements OnInit {

  @Input() influencer: Influencer;
  @Output() add = new EventEmitter();
  @Input() inSharePublic;
  @Input() currency_type;
  @Input() currency_symbol;

  public doughnutChartLabels: Label[] = [];

  public doughnutChartData: MultiDataSet = [[]];

  doughnutChartOptions = {
    legend: {
      display: false,
    },
    responsive: true,
    tooltips: {
      enabled: false,
    },
    hover: {mode: null},
  };

  private donutColors = [
    {
      backgroundColor: [
        'rgba(13, 101, 242, 0.8)',
        'rgba(169, 173, 179, 0.8)',
      ]
    }
  ];

  public doughnutChartType: ChartType = 'doughnut';

  constructor(
      private dialog: MatDialog,
      private store: Store<State>
  ) { }

  ngOnInit() {
    this.doughnutChartLabels.push('Engagement Rate');
    this.doughnutChartLabels.push('');
    if (this.influencer.engagement_rate * 1000 >= 100) {
      this.doughnutChartData[0].push(100);
    } else {
      this.doughnutChartData[0].push(this.influencer.engagement_rate * 1000);
      this.doughnutChartData[0].push(100 - (this.influencer.engagement_rate * 1000));
    }
  }

  openViewMoreModal(data, col1, col2, titleModal) {
    this.dialog.open(ViewMoreModalComponent, {
      width: 'auto',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        data: data,
        col1: col1,
        col2: col2,
        titleModal: titleModal,
      },
    });
  }

  openModalPlans() {
    if ( environment.platform_id === '11posts' ) {
      this.store.dispatch(openPlansDialog());
    }
  }
}
