import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-lateral-profile-audience',
  templateUrl: './lateral-profile-audience.component.html',
  styleUrls: ['./lateral-profile-audience.component.scss']
})
export class LateralProfileAudienceComponent implements OnInit {

  @Input() audience;
  @Input() network;
  @Input() inSharePublic;

  audienceForShow;
  audienceTap;

  constructor(
      private dialog: MatDialog,
      private cdr: ChangeDetectorRef,
      private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (!this.isEmptyObject(this.audience.followers)) {
      this.audienceForShow = this.audience.followers;
      this.audienceTap = 'followers';
    } else if (!this.isEmptyObject(this.audience.likers)) {
      this.audienceForShow = this.audience.likers;
      this.audienceTap = 'likers';
    } else if (!this.isEmptyObject(this.audience.commenters)) {
      this.audienceForShow = this.audience.commenters;
      this.audienceTap = 'commenters';
    }
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  changeTapGeneralAudience(tap) {
    this.audienceForShow = this.audience[tap];
    this.audienceTap = tap;
    this.cdr.detectChanges();
  }

  openViewMoreModal(data, col1, col2, titleModal) {
    // todo recuerda aqui debemos hacer un switch para el titlemodal y cambiar la logica, no podemos mandar en el evento click una variable translate con pipe
    const translatedTitleModal = this.translate.instant(titleModal);
    this.dialog.open(ViewMoreModalComponent, {
      width: 'auto',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        data: data,
        col1: col1,
        col2: col2,
        titleModal: translatedTitleModal
      },
    });
  }
}
