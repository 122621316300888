import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ModalPlansService} from '../../../modal-plans/store/modal-plans.service';
import {MatSnackBar} from '@angular/material';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../../store';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-cancel-plan',
  templateUrl: './cancel-plan.component.html',
  styleUrls: ['./cancel-plan.component.scss']
})
export class CancelPlanComponent implements OnInit {

  stepForCancelPlan = 1;
  loading = false;
  planInfo: [
    {}
  ];
  planNameShown: string;
  dateResponseCancellation: string;

  platformName = environment.platform_id;

  cancelSubscriptionReasonForm: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private _plansService: ModalPlansService,
              private snackBar: MatSnackBar,
              private store: Store<State>,
              private router: Router
              ) { }

  ngOnInit() {
    this.loading = true;
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    this.cancelSubscriptionReasonForm =  new FormGroup({
      reason: new FormControl('' , [Validators.required]),
      contact_with_influencers: new FormControl('' ),
      search: new FormControl('' ),
      campaign: new FormControl('' ),
      brief: new FormControl('' ),
      progress: new FormControl('' ),
      report: new FormControl('' ),
      feedback: new FormControl('', [Validators.required]),

    });
    this.getPlanInfo();
  }

  getPlanInfo() {
    this.subscriptions.push(
        this._plansService.getPlanInfo(this.planNameShown).subscribe( response => {
          this.planInfo = response.body;
          this.planInfo.reverse();
          this.loading = false;
        }, () => {
          this.loading = false;
          this.openSnackBar('Oops something went wrong, please try again', 'Close');
        })
    );
  }


  cancelSubscriptionPlan() {
    const tranformedFormFormRequest = {
      problemsWith: [],
    };
    tranformedFormFormRequest['feedback'] = this.cancelSubscriptionReasonForm.controls['feedback'].value;
    tranformedFormFormRequest['reason'] = this.cancelSubscriptionReasonForm.controls['reason'].value;
    if (this.cancelSubscriptionReasonForm.controls['contact_with_influencers'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('contact_with_influencers');
    }
    if (this.cancelSubscriptionReasonForm.controls['search'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('search');
    }
    if (this.cancelSubscriptionReasonForm.controls['campaign'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('campaign');
    }
    if (this.cancelSubscriptionReasonForm.controls['brief'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('brief');
    }
    if (this.cancelSubscriptionReasonForm.controls['progress'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('progress');
    }
    if (this.cancelSubscriptionReasonForm.controls['report'].value === true) {
      tranformedFormFormRequest['problemsWith'].push('report');
    }
    this.subscriptions.push(
        this._plansService.cancelSubscriptionPlan(tranformedFormFormRequest).subscribe( response => {
          this.dateResponseCancellation = response['body']['cancellation_effective_date'];
          this.stepForCancelPlan = 4;
        }, () => {
          this.openSnackBar('Oops something went wrong, please try again', 'Close');
        })
    );
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  redirectToSearch() {
    this.router.navigate(['/app/search']);
  }

  redirectToWhatsapp() {
    window.open('https://wa.me/+34613052867', '_blank');
  }
}
